@import url('https://fonts.googleapis.com/css2?family=Anek+Tamil:wght@500;600;700&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

:root {
	--header-height: 4.5rem;
	--first-color: #0d6efd;
	--first-color-hover: #0d6efd;
	--first-color-light: #AFA5D9;
	--white-color: #F7F6FB;
	--body-font: 'Anek Tamil', sans-serif;
	--normal-font-size: 1rem;
	--z-fixed: 100
	--color-yellow-danger: #ffb90f;
}
*, {
  font-family: var(--body-font) !important;
  
}

*,
::before,
::after {
	box-sizing: border-box;
}

body {
	font-family: var(--body-font) !important;
	font-size: 15px;
	transition: .5s;
	color: #273138;
	background: #f3f5f6;
  min-height: 100vh;

}

.navbar {
	background-color: var(--first-color);
	box-shadow: 2px 2px 2px rgba(0, 0, 0, 0); 
	padding: 10px 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.nav-item {
	margin-right: 20px;
}

.navbar .nav-link {
  color: #fff;
	text-decoration: none;
}


.custom-footer{
  background-color: var(--first-color);
  color: #fff;
  text-decoration : none;
  margin-top: 25rem;
}

.custom-footer .footer-link a{
 color: #fff;
 text-decoration : none;
}

.btn {
	padding: .7rem 1.2rem;
	text-transform: uppercase;
	letter-spacing: 1.5px;
	font-weight: 700;
	color: #000;
	background-color: #fff;
	border: none;
	border-radius: .7rem;
	transition: all 0.3s ease 0s;
	cursor: pointer;
	outline: none;
	transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.btn:hover {
    transform: scale(0.9);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
	color : black;
}

.btn:active {
    transform: scale(0.9);
}


td {
  height: 50px;
}

.marquee {
	animation: scroll 10s linear infinite;
  }
  
  @keyframes scroll {
	0% {
	  transform: translateX(0);
	}
	100% {
	  transform: translateX(-100%);
	}
  }